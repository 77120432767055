// import React, { Component } from 'react';

// import MainRouter from "./Router";
import {HashRouter, Route, Switch, Redirect} from 'react-router-dom'
import React, {Component} from 'react'
import Cookies from 'universal-cookie'
import './App.scss'
import './scripts'
import appConstants from './config/AppConstants'
// import Loader from './components/Loader';

// for sentry integration
import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'
import config from './config/Config'
const BACKEND_URL = config.BACKEND_URL
const DOMAIN = config.DOMAIN
const cookie = new Cookies()

const StudentLayout = React.lazy(() =>
  import('./components/Layout/StudentLayout'),
)
const LoginAdmin = React.lazy(() => import('./components/Common/LoginAdmin'))
const VerifyEmail = React.lazy(() => import('./components/Common/VerifyEmail'))
// const ForgotPassword = React.lazy(() => import("./components/Common/ForgotPassword"));
const ForgotPassword = React.lazy(() =>
  import('./components/Common/newForgotPassword'),
)
// const ResetPassword = React.lazy(() => import("./components/Common/ResetPassword"));
const ResetPassword = React.lazy(() =>
  import('./components/Common/newResetPassword'),
)
const studentPaymentSuccess = React.lazy(() =>
  import('./components/Common/studentPaymentSuccess'),
)
const studentPaymentFailure = React.lazy(() =>
  import('./components/Common/studentPaymentFailure'),
)
const NewLogin = React.lazy(() => import('./components/Common/newLogin'))
const NewSignUp = React.lazy(() => import('./components/Common/newSignUp'))
const Oauth2Login = React.lazy(() => import('./components/Common/Oauth2Login'))
const CertificateView = React.lazy(() => import('./components/certificate'))

const loading = () =>
  // <div className="custm-loader"><Loader /></div>
  null

Sentry.init({
  dsn:
    'https://7d6cb067412a4a1687e5cf0f6cc2cdb2@o927437.ingest.sentry.io/5898548',
  integrations: [
    new Integrations.BrowserTracing(),
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
  ],
  environment:
    BACKEND_URL === 'http://139.59.70.192:4000'
      ? 'Staging'
      : BACKEND_URL === 'https://api-lms.analytixlabs.co.in'
      ? 'Production'
      : 'Other',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: '',
      token: '',
      loginType: '',
    }
  }

  componentDidMount() {
    const user = this.state.user
    if (user) {
      Sentry.configureScope((scope) => scope.setUser(user))
    }
  }

  componentWillMount() {
    const sessionUser = cookie.get('lmsuser', DOMAIN),
      loginType = parseFloat(cookie.get('loginType', DOMAIN))
    this.setState({
      user: sessionUser || null,
      token: sessionUser?.token || null,
      loginType: loginType || null,
    })
  }
  render() {
    let {user, loginType} = this.state
    let role = (user && user.role && user.role.name) || ''
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" component={NewLogin} />
            <Route exact path="/admin/login" component={LoginAdmin} />
            <Route
              exact
              path="/certificate/:certificateId"
              component={CertificateView}
            />
            <Route exact path="/signup" component={NewSignUp} />
            <Route exact path="/forgotPassword" component={ForgotPassword} />
            <Route
              exact
              path="/verify-email/:type/:userId/:token"
              component={VerifyEmail}
            />
            <Route
              exact
              path="/forgotPassword/:type/:userId/:token"
              component={ResetPassword}
            />
            <Route
              exact
              path="/student/payment-success/"
              component={studentPaymentSuccess}
            />
            <Route
              exact
              path="/student/payment-failure/"
              component={studentPaymentFailure}
            />
            <Route exact path="/oauth2/auth" component={Oauth2Login} />
            <Route
              path="/"
              // exact={true}
              render={(props) =>
                this.state.token != null ? (
                  <div>
                    {
                      loginType.toString() === appConstants.loginType.STUDENT ||
                      loginType.toString() === appConstants.loginType.FACULTY ||
                      role === appConstants.ROLES.trainer.name ||
                      role === appConstants.ROLES.academicAdmin.name ||
                      role === appConstants.ROLES.counsellor.name ? (
                        <StudentLayout {...props}></StudentLayout>
                      ) : null
                      // :  loginType == appConstants.loginType.FACULTY ?  <DefaultLayout {...props}></DefaultLayout> : null
                    }
                    {/* {
                      this.state.loginType === 1 ?
                        <StudentLayout {...props}></StudentLayout>
                        : null
                    }
                    {this.state.loginType === 2 ?
                      <DefaultLayout {...props}></DefaultLayout> : ''
                    } */}
                  </div>
                ) : (
                  // <Layout {...props}></Layout>
                  <Redirect to={'/login'} />
                )
              }
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App
